import PropTypes from 'prop-types';
import { useEffect, useReducer, useCallback, useMemo } from 'react';
import { initializeApp } from 'firebase/app';
import { ref, uploadBytesResumable, getDownloadURL, getStorage } from "firebase/storage";
import {
  getAuth,
  signOut,
  signInWithPopup,
  onAuthStateChanged,
  GoogleAuthProvider,
  GithubAuthProvider,
  TwitterAuthProvider,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from 'firebase/auth';
import { getFirestore, collection, doc, getDoc, setDoc, updateDoc, getDocs, deleteDoc, addDoc, where, query } from 'firebase/firestore';

// config
import { FIREBASE_API } from 'src/config-global';
//
import { AuthContext } from './auth-context';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const firebaseApp = initializeApp(FIREBASE_API);

const AUTH = getAuth(firebaseApp);

const DB = getFirestore(firebaseApp);

const refStorage = getStorage(firebaseApp);

// ----------------------------------------------------------------------

const initialState = {
  user: null,
  loading: true,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(() => {
    try {
      onAuthStateChanged(AUTH, async (user) => {
        if (user) {
          if (user.emailVerified) {
            const userProfile = doc(DB, 'users', user.uid);

            const docSnap = await getDoc(userProfile);

            const profile = docSnap.data();

            dispatch({
              type: 'INITIAL',
              payload: {
                user: {
                  ...user,
                  ...profile,
                  id: user.uid,
                  role: 'admin',
                },
              },
            });
          } else {
            dispatch({
              type: 'INITIAL',
              payload: {
                user: null,
              },
            });
          }
        } else {
          dispatch({
            type: 'INITIAL',
            payload: {
              user: null,
            },
          });
        }
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'INITIAL',
        payload: {
          user: null,
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (email, password) => {
    await signInWithEmailAndPassword(AUTH, email, password);
  }, []);

  const loginWithGoogle = useCallback(async () => {
    const provider = new GoogleAuthProvider();

    await signInWithPopup(AUTH, provider);
  }, []);

  const loginWithGithub = useCallback(async () => {
    const provider = new GithubAuthProvider();

    await signInWithPopup(AUTH, provider);
  }, []);

  const loginWithTwitter = useCallback(async () => {
    const provider = new TwitterAuthProvider();

    await signInWithPopup(AUTH, provider);
  }, []);

  // REGISTER
  const register = useCallback(async (email, password, firstName, lastName) => {
    const newUser = await createUserWithEmailAndPassword(AUTH, email, password);

    await sendEmailVerification(newUser.user);

    const userProfile = doc(collection(DB, 'users'), newUser.user?.uid);

    await setDoc(userProfile, {
      uid: newUser.user?.uid,
      email,
      displayName: `${firstName} ${lastName}`,
    });
  }, []);

  // LOGOUT
  const logout = useCallback(async () => {
    await signOut(AUTH);
  }, []);

  // FORGOT PASSWORD
  const forgotPassword = useCallback(async (email) => {
    await sendPasswordResetEmail(AUTH, email);
  }, []);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user?.emailVerified ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'firebase',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      login,
      logout,
      register,
      forgotPassword,
      loginWithGoogle,
      loginWithGithub,
      loginWithTwitter,
    }),
    [
      status,
      state.user,
      //
      login,
      logout,
      register,
      forgotPassword,
      loginWithGithub,
      loginWithGoogle,
      loginWithTwitter,
    ]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};


export async function updateSingle(col, whereQuery, id, updateData) {
  const collectionRef = collection(DB, col);

  // Create a query to find the document with the matching UID and ID
  const q = query(collectionRef, where(whereQuery, '==', id));

  try {
    const querySnapshot = await getDocs(q);

    // Iterate over the query results
    querySnapshot.forEach(async (docSnapshot) => {
      // Update each matching document
      await updateDoc(doc(collectionRef, docSnapshot.id), updateData);
    });

    console.log('Documents updated successfully');
  } catch (error) {
    console.error('Error updating documents:', error);
  }
}


export async function addDocument(col, user, data) {
  const dbRef = collection(DB, col);
  return new Promise((resolve, reject) => {
    addDoc(dbRef, data)
      .then(docRef => {
        resolve(docRef);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });
}

export async function getMultiple(col, whereQuery, uid) {
  console.log(uid)
  const q = query(collection(DB, col), where("uid", '==', uid));

  const snapshot = await getDocs(q);
  const results = [];
  snapshot.forEach(document => {
    console.log(document.data(), "DATA")
    results.push(document.data());
  });
  return results;
}

export async function getSingle(col, wherequery, id) {
  const snapshot = await getDocs(collection(DB, col), where(wherequery, '==', id));
  const results = [];
  snapshot.forEach(docs => {
    results.push(docs.data())
  });
  return results
}

export async function deleteSingle(col, uid, whereQuery) {
  const collectionRef = collection(DB, col);

  // Create a query to find the document with the matching URL
  const q = query(collectionRef, where(whereQuery, '==', uid));

  try {
    const querySnapshot = await getDocs(q);

    // Iterate over the query results
    querySnapshot.forEach(async (docSnapshot) => {
      // Delete each matching document
      await deleteDoc(doc(collectionRef, docSnapshot.id));
    });

    console.log('Documents deleted successfully');
  } catch (error) {
    console.error('Error deleting documents:', error);
  }
}

export async function uploadMedia(uid, col, file) {
  const storageRef = ref(refStorage, `/files/${file.name}`);
  const uploadTask = uploadBytesResumable(storageRef, file);

  uploadTask.on(
    "state_changed",
    (snapshot) => {
      const percent = Math.round(
        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      );
    },
    (err) => console.log(err),
    () => {
      // download url
      getDownloadURL(uploadTask.snapshot.ref).then((url) => {
        addDocument(col, uid, { url, 'uid': uid }).then((document) => {
          const final = document
          return final
        })
      });
    }
  );
}

export async function getLogo(uid) {
  const snapshot = await getDocs(collection(DB, "logo"), where("uid", '==', uid));
  console.log(uid, "UID: ")
  const results = [];
  snapshot.forEach(docs => {
    console.log(docs.data(), "DATA")
    results.push(docs.data())
  });
  return results[0]
}

export async function deleteLogo(url) {
  const collectionRef = collection(DB, 'logo');

  // Create a query to find the document with the matching URL
  const q = query(collectionRef, where('url', '==', url));

  try {
    const querySnapshot = await getDocs(q);

    // Iterate over the query results
    querySnapshot.forEach(async (docSnapshot) => {
      // Delete each matching document
      await deleteDoc(doc(collectionRef, docSnapshot.id));
    });

    console.log('Documents deleted successfully');
  } catch (error) {
    console.error('Error deleting documents:', error);
  }
}